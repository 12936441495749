<template>
  <div class="container">
    <Video
      ref="videoEl"
      :display_as_gif="autoplay"
      :muted="muted"
      :preload="preload === 'auto' ? true : false"
      :asset="source"
      :options="options"
      :is_in_view="isInView"
      :is_loaded="isFirstFrameLoaded"
      :reset_on_scrollout="resetOnScrollOut"
      :pause_on_scrollout="pauseOnScrollOut"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import { breakpointOrder, getMediaQueryForBreakpoint } from '~/configs/breakpoints';
import { useCloudinary } from '~/composables/cloudinary';
import { getFirstIndex } from '~/util/arrays';

const props = defineProps({
  sourceSet: {
    type: Array as () => SourceSet[],
    default: () => [],
  },
  autoplay: {
    type: Boolean,
    default: false,
  },
  pauseOnScrollOut: {
    type: Boolean,
    default: false,
  },
  resetOnScrollOut: {
    type: Boolean,
    default: false,
  },
  preload: {
    type: String as () => HTMLVideoElement['preload'],
    default: 'metadata',
  },
  lazyLoad: {
    type: Boolean,
    default: false,
  },
  muted: {
    type: Boolean,
    default: true,
  },
  options: {
    type: Object as () => VideoOptions,
    required: true,
  },
});

function doLazyLoad(video: HTMLVideoElement) {
  video.querySelectorAll('source').forEach((source) => {
    const src = source.getAttribute('data-src')!;
    source.setAttribute('src', src);
  });

  video.load();
}

function getActiveSource() {
  const defaultSource = sources.value.find((source) => !source.mediaQuery);
  const mediaQueryMatchedSource = sources.value.find((source) => window.matchMedia(source.mediaQuery!).matches);
  return mediaQueryMatchedSource || defaultSource;
}

function handleResize() {
  const video = videoEl.value;
  const activeSource = getActiveSource();

  if (video && activeSource?.url !== currentVideoSource.value?.url) {
    video.load();
    currentVideoSource.value = activeSource;
  }
}

function handleIntersect(entry: IntersectionObserverEntry) {
  const video = videoEl.value;
  if (!video) {
    return;
  }

  isInView.value = entry.isIntersecting;
}

function handleLoadedData() {
  isFirstFrameLoaded.value = true;
}

const cloudinary = useCloudinary();
const emit = defineEmits(['data-loaded']);
const videoEl = ref<HTMLVideoElement>();
const transparentGif = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
const hasPosters = props.sourceSet.find((source) => source?.poster?.cloudinary_asset?.length);
const poster = ref(hasPosters ? transparentGif : undefined);
const currentVideoSource = ref();
const isFirstFrameLoaded = ref(false);
const isInView = ref(false);
const sources = computed(() =>
  props.sourceSet
    .map((source) => ({
      ...source,
      url: cloudinary.generateVideoUrl(getFirstIndex(source.cloudinary_asset)?.public_id, source.transform),
      poster: source?.poster?.cloudinary_asset
        ? cloudinary.generateImageUrl(getFirstIndex(source?.poster?.cloudinary_asset)?.public_id, source.transform)
        : null,
      mediaQuery: getMediaQueryForBreakpoint(source?.breakpoint),
    }))
    .sort((a, b) => {
      return breakpointOrder[b.breakpoint || 'none'] - breakpointOrder[a.breakpoint || 'none'];
    })
);
const source = getFirstIndex(props.sourceSet[0].cloudinary_asset);
let observer: IntersectionObserver | null = null;
let lazyLoadObserver: IntersectionObserver | null = null;
onMounted(() => {
  const video = videoEl.value;

  if (!video) {
    return;
  }

  if (!(video instanceof Element)) {
    console.warn('[HlpVideo] `video` is not instance of `Element`');
    return;
  }

  currentVideoSource.value = getActiveSource();

  observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => handleIntersect(entry));
  });

  observer.observe(video);

  if (props.lazyLoad) {
    lazyLoadObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && lazyLoadObserver) {
          doLazyLoad(entry.target as HTMLVideoElement);
          lazyLoadObserver.unobserve(entry.target);
        }
      });
    });

    lazyLoadObserver.observe(video);
  }

  video.addEventListener('loadeddata', handleLoadedData);

  if (video.readyState >= 2) {
    isFirstFrameLoaded.value = true;
    emit('data-loaded');
  }

  if (currentVideoSource.value?.poster) {
    poster.value = currentVideoSource.value?.poster;
  }

  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
  if (observer) {
    observer.disconnect();
    observer = null;
  }
  if (lazyLoadObserver) {
    lazyLoadObserver.disconnect();
    lazyLoadObserver = null;
  }
  const video = videoEl.value;
  if (video) {
    // Remove event listeners
    video.removeEventListener('loadeddata', handleLoadedData);
  }
});
</script>

<style scoped>
.container {
  display: grid;
}
.video {
  width: 100%;
}
</style>
